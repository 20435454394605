import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { requiresAuth: false },
    component: () => import("../views/Pages/Login"),
  },

  {
    path: "/",
    meta: { requiresAuth: false },
    component: () => import("../views/Layouts/Container"),
    props: (route) => ({ query: route.query.brandID }),
    children: [
      {
        path: "/chat",
        name: "Chat",
        component: () => import("../views/Chat.vue"),
      },
      {
        path: "/",
        name: "Order",
        // component: () => import("../views/Pages/order/Index"),
        component: () => import("../views/Chat.vue"),
        children: [
          {
            path: "/:id",
            name: "Detail",
            component: () => import("../views/Pages/order/Detail"),
            props: (route) => ({ query: route.query.brandID }),
          },
        ],
      },
    ],
  },
  {
    path: "/decode/:sessionId",
    name: "Decode",
    meta: { requiresAuth: false },
    component: () => import("../views/Pages/Decode"),
  },
  {
    path: "/AccessDenied",
    name: "PermissionDenied",
    meta: { requiresAuth: false },
    component: () => import("../views/Pages/PermissionDenied"),
  },

  // {
  //   path: "/Home",
  //   name: "Container",
  //   component: () => import("../views/Layouts/Container")
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("../views/About.vue"),
  // },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
